import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export default function LoadingScreen() {
  return (
    <div className="tw-w-full tw-h-full tw-absolute tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-top-0 tw-right-0 tw-z-30">
      <div className="tw-w-80 tw-py-6 tw-flex tw-flex-col tw-items-center tw-justify-center">
        <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CircularProgress size={60} style={{ color: "white" }} />
        </Stack>
        <h1 className="tw-text-40 tw-text-white tw-mt-4">Loading...</h1>
      </div>
    </div>
  );
}
