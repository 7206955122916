import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import RTKBaseQuery from '../../constants/RTKBaseQuery';

export const featureApi = createApi({
  reducerPath: 'featureApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['Feature'],
  endpoints: (builder) => ({
    getFeature: builder.query({
      query: () => `feature`,
      providesTags: ['Feature'],
    }),
    storeFeature: builder.mutation({
      query: (body) => ({
        url: `feature`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Feature'],
    }),
    updateFeature: builder.mutation({
      query: ({ body, id }) => ({
        url: `feature/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Feature'],
    }),
    updateFeatureByOrder: builder.mutation({
      query: ({ body }) => ({
        url: `feature/byOrder`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Feature'],
    }),
    deleteFeature: builder.mutation({
      query: (id) => ({
        url: `feature/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Feature'],
    }),
  }),
});

export const {
  useGetFeatureQuery,
  useStoreFeatureMutation,
  useDeleteFeatureMutation,
  useUpdateFeatureMutation,
  useUpdateFeatureByOrderMutation,
} = featureApi;
