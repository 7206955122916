import React from "react";

const LabelText = ({ label, labelClassName = "" }) => {
  return label ? (
    <label
      className={`tw-block tw-mb-2 tw-text-sm tw-font-semibold ${
        labelClassName.length ? labelClassName : "tw-text-gray-600"
      }`}
    >
      {label}
    </label>
  ) : null;
};

export default LabelText;
