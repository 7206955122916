import React from "react";
import LoadingScreen from "./LoadingScreen";

export default function Page({ children, isLoading, className = "" }) {
  return (
    <>
      {isLoading ? <LoadingScreen /> : null}
      <div className={"tw-flex-1 tw-h-full tw-w-full " + className}>
        {children}
      </div>
    </>
  );
}
