import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import RTKBaseQuery from '../../constants/RTKBaseQuery';

export const spaceApi = createApi({
  reducerPath: 'spaceApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['Space'],
  endpoints: (builder) => ({
    getSpaces: builder.query({
      query: () => `space`,
      providesTags: ['Space'],
    }),

    storeSpace: builder.mutation({
      query: (body) => ({
        url: `space`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Space'],
    }),
    getSpace: builder.query({
      query: (date) => ({
        url: `space/byDate/${date}`,
        method: 'GET',
      }),
      providesTags: ["Space"],
    }),
    updateSpace: builder.mutation({
      query: ({ body, id }) => ({
        url: `space/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Space'],
    }),
    updateParticipant: builder.mutation({
      query: ({ body, id }) => ({
        url: `space/schedule/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Space'],
    }),
    deleteSpace: builder.mutation({
      query: (id) => ({
        url: `space/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Space'],
    }),
  }),
});

export const {
  useGetSpacesQuery,
  useStoreSpaceMutation,
  useDeleteSpaceMutation,
  useUpdateSpaceMutation,
  useUpdateParticipantMutation,
  useGetSpaceQuery,
} = spaceApi;
