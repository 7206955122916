import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { Button, Typography } from "@mui/material";
import active_activities from "../../assets/active_activities.png";
import active_arrangement from "../../assets/active_arrangement.png";
import active_home from "../../assets/active_home.png";
import active_meals from "../../assets/active_meals.png";
import active_spaces from "../../assets/active_spaces.png";
import activities from "../../assets/activities.png";
import arrangement from "../../assets/arrangement.png";
import home from "../../assets/home.png";
import login from "../../assets/login.png";
import meals from "../../assets/meals.png";
import spaces from "../../assets/spaces.png";
import useAuth from "../../hooks/useAuth";

const sidebarNavItems = [
  {
    display: "Home",
    icon: <img src={home} />,
    active_icon: <img src={active_home} />,
    to: "/dashboard",
    section: "dashboard",
  },
  {
    display: "Meals",
    icon: <img src={meals} style={{ marginRight: "10px" }} />,
    active_icon: <img src={active_meals} style={{ marginRight: "10px" }} />,
    to: "/meal",
    section: "meal",
  },
  {
    display: "Spaces",
    icon: <img src={spaces} />,
    active_icon: <img src={active_spaces} />,
    to: "/space",
    section: "space",
  },
  {
    display: "Activities",
    icon: <img src={activities} />,
    active_icon: <img src={active_activities} />,
    to: "/activity",
    section: "activity",
  },
  {
    display: "Arrangement",
    icon: <img src={arrangement} />,
    active_icon: <img src={active_arrangement} />,
    to: "/arrangement",
    section: "arrangement",
  },
];

const Sidebar = () => {
  const { logout } = useAuth();

  const [activeIndex, setActiveIndex] = useState(0);
  const sidebarRef = useRef();
  const location = useLocation();

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div className="tw-w-80 tw-py-12 tw-bg-white tw-h-full tw-flex tw-flex-col tw-justify-between tw-items-center">
      <div ref={sidebarRef} className="sidebar__menu tw-w-[90%]">
        {sidebarNavItems.map((item, index) => (
          <Link to={item.to} key={index}>
            <div
              className={`${activeIndex === index ? "tw-bg-[#71C0BB]" : ""}
             tw-flex tw-items-center tw-py-4 tw-px-4  tw-rounded-md              
              `}
            >
              {activeIndex === index ? (
                <div>{item.active_icon}</div>
              ) : (
                <div>{item.icon}</div>
              )}
              <div
                className={`tw-ml-4 ${
                  activeIndex === index ? "tw-bg-[#71C0BB] tw-text-white" : ""
                }              
              `}
              >
                {item.display}
              </div>
            </div>
          </Link>
        ))}
      </div>

      <Button
        variant="outlined"
        sx={{ p: 1.5, borderRadius: 2 }}
        onClick={logout}
        className="tw-w-[90%]"
        startIcon={<img src={login} className="tw-[20%]" alt="" />}
      >
        <Typography
          className="tw-flex-1 tw-font-bold tw-pr-[10%]"
          color="#106D97"
        >
          Logout
        </Typography>
      </Button>
    </div>
  );
};

export default Sidebar;
