import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import RTKBaseQuery from "../../constants/RTKBaseQuery";

export const mealApi = createApi({
  reducerPath: "mealApi",
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ["Meal"],

  endpoints: (builder) => ({
    getMeals: builder.query({
      query: () => `meal`,
      providesTags: ["Meal"],
    }),
    getMeal: builder.query({
      query: (date) => ({
        url: `meal/byDate/${date}`,
        method: "GET",
      }),
      providesTags: ["Meal"],
    }),
    storeMeal: builder.mutation({
      query: (body) => ({
        url: `meal`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Meal"],
    }),
    updateMeal: builder.mutation({
      query: ({ body, id }) => ({
        url: `meal/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Meal"],
    }),
    deleteMeal: builder.mutation({
      query: (id) => ({
        url: `meal/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Meal"],
    }),
  }),
});

export const {
  useGetMealsQuery,
  useDeleteMealMutation,
  useStoreMealMutation,
  useUpdateMealMutation,
  useGetMealQuery,
} = mealApi;
