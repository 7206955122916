import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { activityApi } from './api/activityApi';
import { arrangementApi } from './api/arrangementApi';
import { authApi } from './api/authApi';
import { mealApi } from './api/mealApi';
import { userApi } from './api/userApi';
import { abilityApi } from './api/abilityApi';
import { spaceApi } from './api/spaceApi';
import { featureApi } from './api/featureApi';
const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [mealApi.reducerPath]: mealApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [arrangementApi.reducerPath]: arrangementApi.reducer,
    [abilityApi.reducerPath]: abilityApi.reducer,
    [spaceApi.reducerPath]: spaceApi.reducer,
    [featureApi.reducerPath]: featureApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(mealApi.middleware)
      .concat(activityApi.middleware)
      .concat(arrangementApi.middleware)
      .concat(authApi.middleware)
      .concat(abilityApi.middleware)
      .concat(spaceApi.middleware)
      .concat(featureApi.middleware),
});

setupListeners(store.dispatch);

export default store;
