import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { Dropdown } from "primereact/dropdown";

import React from "react";
import ErrorText from "../ErrorText";
import LabelText from "../LabelText";
import "./style.css";

const CustomDropdown = ({
  name = "",
  value = "",
  error = "",
  onChange,
  label = "",
  placeholder = "",
  className = "",
  labelClassName = "",
  helperTextTop,
  helperTextBottom,
  inputClassName = "border border-none",
  preFix,
  maxLength,
  options = [],
  ...other
}) => {
  const selectedValueTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const OptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <div className={`tw-mb-2 tw-w-full tw-flex tw-flex-col ${className}`}>
      <LabelText label={label} labelClassName={labelClassName} />
      <Dropdown
        value={value}
        options={options}
        onChange={(e) => onChange(e.value)}
        placeholder={placeholder}
        filter
        className="tw-w-full tw-my-0 tw-py-0 p-inputtext-sm"
        valueTemplate={selectedValueTemplate}
        itemTemplate={OptionTemplate}
        optionLabel="label"
        optionValue="value"
        {...other}
      />
      <ErrorText text={error} />
    </div>
  );
};

export default CustomDropdown;
