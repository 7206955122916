import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import RTKBaseQuery from "../../constants/RTKBaseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  endpoints: (builder) => ({
    getAuthUser: builder.query({
      query: () => `auth/user`,
    }),
    getUsers: builder.query({
      query: () => `user`,
    }),
  }),
});

export const { useGetAuthUserQuery, useGetUsersQuery } = userApi;
