import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ErrorText from "./ErrorText";
import LabelText from "./LabelText";

const Textarea = ({
  name,
  label = "",
  className = "",
  labelClassName = "",
  helperTextTop,
  helperTextBottom,
  inputClassName = "border border-none",
  rows = 3,
  maxLength,
  ...other
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={`tw-mb-2 tw-w-full ${className}`}>
          <LabelText label={label} />
          <div className="tw-relative">
            <textarea
              {...field}
              rows={rows}
              className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-grey-300 tw-p-4  tw-text-sm tw-shadow-sm tw-focus:border-indigo-500 tw-focus:ring-indigo-500 tw-sm:text-sm"
              // className={` disabled:tw-bg-gray-300 tw-text-sm tw-w-full ${inputClassName}`}
              maxLength={maxLength}
              {...other}
            />
          </div>
          <ErrorText text={error?.message} />
        </div>
      )}
    />
  );
};

export default Textarea;
