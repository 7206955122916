import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import React from 'react';

export default function SubmitButton({
  onClick,
  name,
  icon,
  className,
  iconClass,
  variant = 'body2',
  type = 'submit',
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`tw-rounded-md tw-hover:bg-white tw-hover:text-textColor   tw-text-base tw-font-bold tw-py-2 tw-px-12    tw-hover:border-textColor ${className}`}
    >
      <div className='tw-flex tw-justify- tw-items-center'>
        {<Icon className={iconClass} icon={icon} />}
        <Typography variant={variant} fontWeight='bold'>
          {name}
        </Typography>
      </div>
    </button>
  );
}
