import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Icon } from "@iconify/react";
// import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import cup from "../assets/images/Frame.png";
import Lunch from "../assets/images/Vector (2).png";
import spone from "../assets/images/Vector (3).png";

import IconButton from "./form/buttons/IconButton";
import { SubmitButton } from "./form";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon className="" color="black" icon={"ic:baseline-close"} />
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ name }) {
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(15);
  let bgColor = "";
  let iconColor = "";
  let image = "";
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (name == "BreakFast") {
    bgColor = "tw-bg-lightOrange";
    iconColor = "secondary";
    image = cup;
  } else if (name == "Lunch") {
    bgColor = "tw-bg-[#D2EBE8]";
    iconColor = "darkGreen";
    image = Lunch;
  } else if (name == "Dinner") {
    bgColor = "tw-bg-[#D6E6EC]";
    iconColor = "primary";
    image = spone;
  }
  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        className={`tw-border-none ${bgColor} `}
        icon={
          <Icon
            color={iconColor}
            className={`tw-text-${iconColor}`}
            icon="material-symbols:add"
          />
        }
      />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="tw-flex tw-justify-end tw-p-2">
          <IconButton
            icon={
              <Icon className="" color="black" icon={"ic:baseline-close"} />
            }
            onClick={handleClose}
            className={
              "tw-rounded-full tw-w-6 tw-h-6 tw-px-1 tw-py-1 tw-flex tw-justify-between tw-items-center"
            }
          />
        </div>
        <DialogContent>
          <div className="tw-px-8 tw-py-5 tw-w-[400px]">
            <div className="tw-flex tw-flex-col tw-justify-between tw-items-center">
              <img className=" tw-mr-8" src={image} />
              <h1 className="tw-font-semibold tw-text-2xl ">Add {name} Type</h1>
            </div>
            <h1 className="tw-font-semibold tw-text-base tw-py-2 ">Type</h1>
            <div className="tw-border-[1px] tw-borer- tw-p-4 tw-rounded-xl">
              <h1 className=" tw-text-sm  ">Normal</h1>
            </div>
            <h1 className="tw-font-semibold tw-text-base tw-py-2 ">
              Participate Capacity
            </h1>
            <div className="tw-border-[1px] tw-borer- tw-p-4 tw-rounded-xl tw-flex tw-justify-between tw-items-center">
              <IconButton
                onClick={() => {
                  setCount(count - 1);
                }}
                className={"tw-border-none tw-py-0"}
                icon={
                  <Icon className="" color="black" icon={"carbon:subtract"} />
                }
              />
              <h1 className=" tw-text-sm  ">{count}</h1>

              <IconButton
                onClick={() => {
                  setCount(count + 1);
                }}
                icon={
                  <Icon
                    className=""
                    color="[#ED7626]"
                    icon={"material-symbols:add"}
                  />
                }
                className={"tw-border-none tw-py-0"}
              />
            </div>
          </div>
        </DialogContent>
        <div className="tw-flex tw-justify-center tw-pb-8">
          <SubmitButton
            name={"Save"}
            onClick={handleClose}
            className={"tw-px-20 tw-py-2"}
          />
        </div>
      </BootstrapDialog>
    </div>
  );
}
