import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "../../index.css";

import { Dialog } from "primereact/dialog";
import React from "react";
import "./style.css";

const CustomDialog = ({
  show,
  setShow,
  header = "",
  children,
  footer,
  style = { width: "80vw" },
  onClose,
}) => {
  const onHide = (name) => {
    setShow(false);
    if (onClose) onClose();
  };

  return (
    <div className="dialog-demo">
      <div className="card">
        <Dialog
          header={header}
          visible={show}
          maximizable
          modal
          style={style}
          footer={footer}
          onHide={onHide}
        >
          {children}
        </Dialog>
      </div>
    </div>
  );
};

export default CustomDialog;
