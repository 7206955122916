import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";
import React from "react";

export default function SectionHeader({ title = "", date = "", right }) {
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-my-2">
      <div className="tw-flex  tw-items-center tw-space-x-2">
        <Typography variant="h6" color="#106D97">
          {title}
        </Typography>
        <Icon icon="mdi:dot" />

        <Typography variant="subtitle1" color="#53A69D">
          {date}
        </Typography>
      </div>
      {right}
    </div>
  );
}
