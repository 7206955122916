import React from "react";

const IconButton = ({ icon, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      // type="submit"
      className={`tw-rounded-lg tw-border tw-border-gray-300 tw-px-2 tw-py-2  tw-font-[14px] tw-text-black tw-hover:bg-white tw-hover:text-orange tw-focus:outline-none tw-focus:ring-0 ${className}`}
    >
      {icon}
    </button>
  );
};

export default IconButton;
