import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PageBack({ title = "" }) {
  const navigate = useNavigate();
  return (
    <div className="tw-flex tw-justify-apart tw-items-center tw-my-3">
      <button type="button" onClick={() => navigate(-1)}>
        <Icon className="tw-text-3xl" icon={"ion:arrow-back-circle-outline"} />
      </button>

      <h1 className="tw-text-2xl tw-font-bold tw-ml-6">{title}</h1>
    </div>
  );
}
