import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import RTKBaseQuery from '../../constants/RTKBaseQuery';

export const arrangementApi = createApi({
  reducerPath: 'arrangementApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['Arrangement'],
  endpoints: (builder) => ({
    getArrangements: builder.query({
      query: () => `arrangement`,
      providesTags: ['Arrangement'],
    }),
    storeArrangement: builder.mutation({
      query: (body) => ({
        url: `arrangement`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Arrangement'],
    }),
    updateArrangement: builder.mutation({
      query: ({ body, id }) => ({
        url: `arrangement/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Arrangement'],
    }),

    deleteArrangement: builder.mutation({
      query: (id) => ({
        url: `arrangement/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Arrangement'],
    }),
  }),
});

export const {
  useGetArrangementsQuery,
  useStoreArrangementMutation,
  useDeleteArrangementMutation,
  useUpdateArrangementMutation,
} = arrangementApi;
