import Avatar from "@mui/material/Avatar";
import { Outlet } from "react-router-dom";
import logo from "../../assets/IYC-Logo.png";
import profile from "../../assets/profile.png";
import Sidebar from "../../components/sidebar/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function DashboardLayout() {
  const {user} = useAuth()
  return (
    <div className="tw-h-screen tw-w-screen">
      <div className="tw-fixed tw-px-8 tw-py-2 tw-w-full tw-flex tw-items-center tw-justify-between tw-bg-[#106D97] tw-z-10 tw-cursor-pointer">
        <img src={logo} alt="" />
        <Avatar alt="Remy Sharp" src={user?.image || profile} className="tw-border" />
      </div>
      <div className="tw-pt-12 tw-h-full tw-bg-[#f8fbfd] tw-flex tw-justify-between">
        <Sidebar />
        <div className="tw-w-full tw-h-full tw-p-4 tw-overflow-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
