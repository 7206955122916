import React from "react";
import ErrorText from "./ErrorText";
import { Controller, useFormContext } from "react-hook-form";
import LabelText from "./LabelText";

const TextInput = ({
  name,
  label,
  type = "text",
  placeholder,
  labelClass,
  ...others
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error, isDirty, isTouched } }) => (
        <div className="tw-col-span-6 tw-sm:col-span-3 tw-mb-2">
          <LabelText label={label} />
          <input
            {...field}
            type={type}
            placeholder={placeholder}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-grey-300  tw-py-3 tw-pl-4  tw-shadow-sm tw-focus:border-indigo-500 tw-focus:ring-indigo-500 tw-sm:text-sm"
            {...others}
          />
          <ErrorText text={error?.message} />
        </div>
      )}
    />
  );
};

export default TextInput;
