import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import RTKBaseQuery from "../../constants/RTKBaseQuery";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ["Activity"],
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: () => `activity`,
      providesTags: ["Activity"],
    }),
    getActivity: builder.query({
      query: (date) => ({
        url: `activity/byDate/${date}`,
        method: "GET",
      }),
      providesTags: ["Activity"],
    }),
    storeActivity: builder.mutation({
      query: (body) => ({
        url: `activity`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Activity"],
    }),
    updateActivity: builder.mutation({
      query: ({ body, id }) => ({
        url: `activity/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Activity"],
    }),
    deleteActivity: builder.mutation({
      query: (id) => ({
        url: `activity/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Activity"],
    }),
  }),
});

export const {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
  useStoreActivityMutation,
  useUpdateActivityMutation,
  useGetActivityQuery,
} = activityApi;
