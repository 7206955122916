import { Typography } from '@mui/material';
import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function SnackBar({ title, open, close }) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={2000}
      onClose={close}
    >
      <Alert onClose={close} severity='success' sx={{ width: '100%' }}>
        {title}
      </Alert>
    </Snackbar>
  );
}
