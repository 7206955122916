import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import RTKBaseQuery from '../../constants/RTKBaseQuery';

export const abilityApi = createApi({
  reducerPath: 'abilityApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['Ability'],
  endpoints: (builder) => ({
    getAbilities: builder.query({
      query: () => `ability`,
      providesTags: ['Ability'],
    }),
    storeAbility: builder.mutation({
      query: (body) => ({
        url: `ability`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Ability'],
    }),
    updateAbility: builder.mutation({
      query: ({ body, id }) => ({
        url: `ability/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Ability'],
    }),

    deleteAbility: builder.mutation({
      query: (id) => ({
        url: `ability/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ability'],
    }),
  }),
});

export const {
  useGetAbilitiesQuery,
  useStoreAbilityMutation,
  useUpdateAbilityMutation,
  useDeleteAbilityMutation,
} = abilityApi;
