import React from "react";
import ErrorText from "./ErrorText";
import { Controller, useFormContext } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import LabelText from "./LabelText";

const TimeInput = ({ name, label, placeholder, labelClass, showError = true, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error, isDirty, isTouched } }) => (
        <>
          <LabelText label={label} />
          <Calendar
            {...field}
            timeOnly
            className="tw-w-full  "
            inputClassName="tw-w-full   "
            placeholder={placeholder}
            stepMinute={15}
            {...other}
          />

        {showError &&  <ErrorText text={error?.message} />}
        </>
      )}
    />
  );
};

export default TimeInput;
