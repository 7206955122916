import { Typography } from "@mui/material";
import React from "react";

export default function PageHeader({ title, right }) {
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-my-2">
      <Typography variant="h5" fontWeight={"bold"}>
        {title}
      </Typography>
      {right}
    </div>
  );
}
