import { Icon } from "@iconify/react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
// import { HelperText } from "./form";
import ErrorText from "./ErrorText";

const MAX_SIZE = 5242880;

const CONFIG = {
  accept: {
    "image/*": [],
  },
  multiple: true,
  maxSize: MAX_SIZE,
};

// const ImagePreview = ({ file, onRemove }) => {
//   return (
//     <div
//       style={{ minWidth: 100 }}
//       className="tw-relative tw-h-[100px] tw-w-[100px] tw-p-1 tw-flex-grow-1 tw-text-end tw-bg-gray-200"
//     >
//       <img
//         src={file ? file.preview : null}
//         className="tw-w-full tw-h-full"
//         alt="event_image"
//       />
//       <div
//         onClick={onRemove}
//         className="tw-absolute tw-top-0 tw-right-0 tw-bg-gray-700 tw-rounded-full tw-text-white tw-cursor-pointer"
//       >
//         <Icon icon="ri:close-circle-fill" />
//       </div>
//     </div>
//   );
// };

const CoverImageUploader = () => {
  const { setValue, watch, register, setError, unregister, trigger } =
    useFormContext();

  const coverImage = watch("coverImage");

  const onDrop = (acceptedFiles, rejectedFile) => {
    if ([...rejectedFile].length > 0) {
      register("maxFileSize");
      setError("maxFileSize", {
        type: "required",
        message: "Max file size is 5mb for photos",
      });
    } else {
      unregister("maxFileSize");
    }
    const filePreview = Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    setValue("coverImage", filePreview);
    trigger("coverImage");
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...CONFIG,
    multiple: false,
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      style={{}}
      className="tw-h-full tw-w-full  tw-flex tw-flex-col "
    >
      <input {...getInputProps()} onSubmit={(e) => console.log(e.target)} />
      <div className="tw-relative tw-h-full tw-w-full tw-text-end tw-border tw-border-primary">
        <div className="tw-relative tw-h-full tw-w-full tw-text-end tw-border tw-border-primary">
          {coverImage ? (
            <img
              id="eventCoverImageId"
              src={coverImage.preview}
              className="tw-w-full tw-h-full"
              alt="event_cover_image"
            />
          ) : (
            <div className="tw-cursor-pointer tw-w-full tw-h-full"></div>
          )}
          {coverImage && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setValue("coverImage", "");
              }}
              className="tw-absolute tw-top-0 tw-right-0 tw-bg-gray-700 tw-rounded-full tw-text-white tw-cursor-pointer"
            >
              <Icon icon="ri:close-circle-fill" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const UploaderBody = ({ coverImage, eventImages }) => {
  return (
    <div className="tw-relative tw-h-40  tw-bg-[#F1F6F8] tw-border-dashed  tw-border-2  tw-border-gray-300  tw-w-full tw-flex tw-items-center ">
      <CoverImageUploader />
      {(eventImages && [...eventImages]?.length > 0) || coverImage ? null : (
        <div className=" tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-absolute tw-border-dashed  tw-border-gray-300 tw-bg-[#F1F6F8]">
          <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center hover:tw-cursor-pointer">
            {/* <Icon
              icon="akar-icons:plus"
              className="tw-text-white tw-text-4xl"
            /> */}
            <svg
              className="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <label className="tw-text-white hover:tw-cursor-pointer tw-text-[#8F9A9E]">
              Upload image
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

const DropZone = ({ label, name, error, file }) => {
  const {
    setValue,
    trigger,
    watch,
    formState: { errors },
    setError,
    register,
    unregister,
  } = useFormContext();

  const coverImage = watch("coverImage");
  const eventImages = watch("eventImages");

  const onDrop = (acceptedFiles, rejectedFile) => {
    if ([...rejectedFile].length > 0) {
      register("maxFileSize");
      setError("maxFileSize", {
        type: "required",
        message: "Max file size is 5mb for photos",
      });
    } else {
      unregister("maxFileSize");
    }

    let cover;
    const images = [];

    acceptedFiles.forEach((file, index) => {
      const filePreview = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (index === 0) {
        cover = filePreview;
      } else {
        images.push(filePreview);
      }
    });
    setValue("eventImages", images);
    setValue("coverImage", cover);
    trigger("coverImage");
    trigger("eventImages");
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    ...CONFIG,
    onDrop,
  });

  return (
    <div className="tw-flex-1 tw-mb-2  ">
      <label className="tw-block tw-mb-2 tw-text-sm tw-font-semibold tw-text-black">
        {label}
      </label>

      <div className="tw-w-full">
        <div
          {...(eventImages?.length || coverImage ? {} : getRootProps())}
          style={{ outline: "none" }}
          className="tw-relative tw-overflow-hidden tw-rounded-md tw-border hover:tw-opacity-0.72"
        >
          {eventImages?.length || coverImage ? null : (
            <input {...getInputProps()} />
          )}

          <UploaderBody coverImage={coverImage} eventImages={eventImages} />
        </div>
      </div>
      <ErrorText text={errors["coverImage"]?.message} />
      <ErrorText text={errors["eventImages"]?.message} />
      {errors?.maxFileSize && errors?.maxFileSize?.message && (
        <ErrorText text={errors["maxFileSize"]?.message} />
      )}
    </div>
  );
};

export default DropZone;
