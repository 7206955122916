import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { InputNumber } from "primereact/inputnumber";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ErrorText from "../ErrorText";
import HelperText from "../HelperText";
import LabelText from "../LabelText";

const RangeInput = ({
  name = "",
  label = "",
  placeholder = "",
  className = "",
  labelClassName = "",
  helperTextTop,
  helperTextBottom,
  inputClassName = "border border-none",

  ...other
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error, isDirty, isTouched } }) => (
        <div className={`tw-w-full tw-flex tw-flex-col ${className}`}>
          <LabelText label={label} labelClassName={labelClassName} />
          <div className="tw-border tw-rounded tw-overflow-hidden">
            <InputNumber
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              defaultValue={0}
              value={parseInt(field.value)}
              inputId="horizontal"
              onValueChange={(e) => {
                field.onChange(e.value);
              }}
              showButtons
              buttonLayout="horizontal"
              step={1}
              placeholder={placeholder}
              className="tw-w-full "
              decrementButtonClassName="p-button-danger p-button-text"
              incrementButtonClassName="p-button-primary p-button-text"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              inputClassName="tw-text-center tw-border-0 tw-outline-0 focus:tw-ring-0"
              {...other}
            />
          </div>
          <ErrorText text={error?.message} />
        </div>
      )}
    />
  );
};

export default RangeInput;
