import { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { LoadingScreen } from "../components";
import { PATH_AFTER_LOGIN } from "../config";
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
import AuthLayout from "../layouts/auth";
import DashboardLayout from "../layouts/dashbaord";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen isDashboard={pathname?.includes("/dashboard")} />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: "auth",
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "login1",
          element: <Login1 />,
        },
        {
          path: "login2",
          element: <Login2 />,
        },
        {
          path: "splash",
          element: <Splash />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "feature", element: <Feature /> },
        { path: "ability", element: <Ability /> },
        { path: "meal", element: <Meal /> },
        { path: "space", element: <Space /> },
        { path: "activity", element: <Activity /> },
        { path: "activity-detail", element: <ActivityDetail /> },
        { path: "arrangement", element: <Arrangement /> },
      ],
    },

    { path: "*", element: <NotFound /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login1 = Loadable(lazy(() => import("../pages/auth/Login1")));
const Login2 = Loadable(lazy(() => import("../pages/auth/Login2")));
const Splash = Loadable(lazy(() => import("../pages/auth/Splash")));

// Dashboard
const Dashboard = Loadable(lazy(() => import("../pages/dashbaord/Home")));
const Users = Loadable(lazy(() => import("../pages/dashbaord/Users")));
const Feature = Loadable(lazy(() => import("../pages/dashbaord/Feature")));
const Ability = Loadable(lazy(() => import("../pages/dashbaord/Ability")));
const Meal = Loadable(lazy(() => import("../pages/dashbaord/Meal")));
const Space = Loadable(lazy(() => import("../pages/dashbaord/Space")));
const Activity = Loadable(lazy(() => import("../pages/dashbaord/Activity")));
const ActivityDetail = Loadable(
  lazy(() => import("../pages/dashbaord/ActivityDetail"))
);
const Arrangement = Loadable(
  lazy(() => import("../pages/dashbaord/Arrangement"))
);

// Error
const NotFound = Loadable(lazy(() => import("../pages/error/NotFound")));
