// ----------------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/";
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login1: path(ROOTS_AUTH, "/login1"),
  login2: path(ROOTS_AUTH, "/login2"),
};

export const PATH_PAGE = {
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, "dashboard"),
    feature: path(ROOTS_DASHBOARD, "feature"),
    users: path(ROOTS_DASHBOARD, "users"),
    ability: path(ROOTS_DASHBOARD, "ability"),
    meal: path(ROOTS_DASHBOARD, "meal"),
    space: path(ROOTS_DASHBOARD, "space"),
    activity: path(ROOTS_DASHBOARD, "activity"),
    activityDetail: path(ROOTS_DASHBOARD, "activity-detail"),
    arrangement: path(ROOTS_DASHBOARD, "arrangement"),
  },
};
