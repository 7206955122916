import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { MultiSelect } from "primereact/multiselect";
import React from "react";
import ErrorText from "../ErrorText";
import LabelText from "../LabelText";
import "./style.css";

const CustomMultiSelect = ({
  value,
  error = "",
  onChange,
  label = "",
  placeholder = "",
  className = "",
  labelClassName = "",
  options = [],
  helperTextTop,
  helperTextBottom,
  inputClassName = "border border-none",
  preFix,
  maxLength,
  ...other
}) => {
  const itemTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.label}</div>
      </div>
    );
  };

  const panelFooterTemplate = (value) => {
    const selectedItems = value?.value || [];
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="tw-py-2 tw-px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <>
      <div className={`tw-mb-2 tw-w-full tw-flex tw-flex-col ${className}`}>
        <LabelText label={label} labelClassName={labelClassName} />

        <MultiSelect
          value={value}
          options={options}
          onChange={(e) => onChange(e.value)}
          optionLabel="label"
          optionValue="value"
          placeholder={placeholder}
          filter
          className="tw-w-full tw-my-0 tw-py-0 p-inputtext-sm"
          itemTemplate={itemTemplate}
          panelFooterTemplate={panelFooterTemplate}
          panelHeaderTemplate={() => null}
          display="chip"
          {...other}
        />

        <ErrorText text={error} />
      </div>
    </>
  );
};

export default CustomMultiSelect;
