import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { Calendar } from "primereact/calendar";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ErrorText from "../ErrorText";
import LabelText from "../LabelText";

const DateTimePicker = ({
  name = "",
  label = "",
  placeholder = "",
  className = "",
  labelClassName = "",
  helperTextTop,
  helperTextBottom,
  inputClassName = "border border-none",

  ...other
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className={`tw-mb-2 tw-w-full tw-flex tw-flex-col ${className}`}>
          <LabelText label={label} labelClassName={labelClassName} />
          <Calendar
            {...field}
            value={new Date(field.value)}
            placeholder={placeholder}
            onChange={(e) => field.onChange(e.value)}
            className="tw-w-full"
            {...other}
          />

          <ErrorText text={error?.message} />
        </div>
      )}
    />
  );
};

export default DateTimePicker;
